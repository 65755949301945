import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/Layout/layout"
// import Image from "../components/image"
import SEO from "../../components/SEO/seo"
import HeroInner from "../../components/HeroInner/hero-inner"
// import ArticleFullWidth from "../../components/ArticleFullWidth/article-full-width"
// import ServicesBrief from "../components/ServicesBrief/services-brief"
// import Banner from "../components/Banner/banner"

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <HeroInner title="Services" />
  </Layout>
)

export default ServicesPage
